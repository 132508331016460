import React, { ReactElement } from 'react';

import { useRootStore } from '@stores/index';

const Top100 = (props: { id: number }): ReactElement => {
  const { layout } = useRootStore();
  return (
    <React.Fragment>
      <script
        nonce={layout.cspNonce}
        dangerouslySetInnerHTML={{ __html: `
          (function (w, d, c) {
            (w[c] = w[c] || []).push(function() {
              var options = {
                project: ${props.id},
                trackHashes: true,
                protocol: 'https',
                attributes_dataset: [
                  'cerber-topline',
                  'logo',
                  'ui',
                  'help'
                ]
              };
              try {
                w.top100Counter = new top100(options);
              } catch(e) { }
            });
            var n = d.getElementsByTagName("script")[0],
            s = d.createElement("script"),
            f = function () { n.parentNode.insertBefore(s, n); };
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://st.top100.ru/top100/top100.js";
            if (w.opera == "[object Opera]") {
              d.addEventListener("DOMContentLoaded", f, false);
            } else { f(); }
          })(window, document, "_top100q");
        `,}}
      />
        <noscript>
          <img
            src={`https://counter.rambler.ru/top100.cnt?pid=${props.id}`}
            alt=""
          />
        </noscript>
    </React.Fragment>
  );
}

export default Top100;
