import React, { ReactElement, useState, useEffect } from 'react';
import TopLine, { UserProfileType } from '@rambler-components/topline';
import Geo from '@rambler-components/geo';
import Popup from '@rambler-components/popup';

import { useRootStore } from '@stores/index';

import '@rambler-components/topline/styles.css';
import '@rambler-components/header/styles.css';
import '@rambler-components/geo/styles.css';

import { css } from '@linaria/core';
import { styled } from '@linaria/react';

const boxSizing = css`
  * {
    box-sizing: border-box;
  }
`;
const HeaderUser = styled.div``;

const HeaderUserComponent = (): ReactElement | null => {
  const { toplineUser, projects, search } = useRootStore();
  const [userProfile, setUserProfile] = useState<UserProfileType | null>(null);
  const [isGeoOpened, setGeoOpened] = useState(false);
  const [showTopline, setShowTopline] = useState(false);

  useEffect(() => {
    if (userProfile?.info.email) {
      toplineUser.setEmail(userProfile?.info.email);
    }
  }, [userProfile, toplineUser]);

  useEffect(() => {
    setShowTopline(true);
  }, [showTopline]);

  return (
    showTopline ? (
      <HeaderUser className={boxSizing}>
        <TopLine
          projectCode="help"
          projectName="помощь"
          onUserInfo={setUserProfile}
          onOpenGeo={() => setGeoOpened(true)}
          withSuggestions={false}
          search={{
            placeholder: projects.project
              ? `Поиск в разделе «${projects.project.title}»`
              : `Поиск в Помощи`,
            value: search.query || '',
            target: '_self',
            action: '/search',
            onSubmit: (e, query) => {
              e.preventDefault();
              window.location.href = (
                projects.project
                  ? '/search/' + projects.project.slug + '/' + encodeURIComponent(query)
                  : '/search/' + encodeURIComponent(query)
              );
            },
          }}
        />
        <Popup
          width={560}
          isOpen={isGeoOpened}
          onClose={() => setGeoOpened(false)}
          className={boxSizing}
        >
          <Geo onClose={() => setGeoOpened(false)} />
        </Popup>
      </HeaderUser>
    ) : (
      null
    )
  );
};

export default HeaderUserComponent;
