import React, { ReactElement } from 'react';

import { useRootStore } from '@stores/index';

const YandexMetrika = (props: { ids: Array<number> }): ReactElement => {
  const { layout } = useRootStore();
  return (
    <React.Fragment>
      <script
        nonce={layout.cspNonce}
        dangerouslySetInnerHTML={{ __html: `
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
          ${
            props.ids.map(
              id => `
                ym(${id}, "init", {
                  params: {
                    version: "${APP_VERSION}"
                  },
                  clickmap:true,
                  trackLinks:true,
                  accurateTrackBounce:true,
                  triggerEvent: true,
                });
              `
            )
            .join('')
          }
        `,}}
      />
      {props.ids.map(id => (
        <noscript key={id}>
          <div>
            <img
              src={`https://mc.yandex.ru/watch/${id}`}
              style={{ position: 'absolute', left: '-9999px' }}
              alt=""
            />
          </div>
        </noscript>
      ))}
    </React.Fragment>
  );
}

export default YandexMetrika;
