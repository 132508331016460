import { makeAutoObservable } from 'mobx';

import { EPageContext } from '@rambler-help/shared';
import { EPageType } from '@rambler-help/shared';

export default class LayoutStore {

  constructor(initialData?: LayoutStore) {
    if (initialData) {
      Object.assign(this, initialData);
    }
    makeAutoObservable(this);
  }

  pageContext: EPageContext = EPageContext.User;
  pageType: EPageType | undefined;
  pageTitle = '';
  cspNonce = '';

  setPageContext = (type: EPageContext): void => {
    this.pageContext = type;
  };

  setPageType = (type: EPageType): void => {
    this.pageType = type;
  };

  setPageTitle = (title: string): void => {
    this.pageTitle = title;
  };

  setCspNonce = (nonce: string): void => {
    this.cspNonce = nonce;
  };

}
